import L from 'leaflet';
/**
 *
 * @param {*} val 转换后的数值
 * @param {*} fromUnit 转换前单位
 * @param {*} toUnit 转换后单位
 */
function unitConverter(val, fromUnit = 'squareMeter', toUnit = 'mu') {
  var fRate = {
    //换算率 'hectare'：公顷  mu: 亩 squareMeter: 平方米
    hectare: { mu: 15, squareMeter: 10000 },
    mu: { squareMeter: 10000 / 15, hectare: 1 / 15 },
    squareMeter: { mu: 15 / 10000, hectare: 1 / 10000 }
  };
  let result = (val * fRate[fromUnit][toUnit]).toFixed(2);
  return result;
}
export function isUndefined(value) {
  return value === 'undefined';
}
// 初始化地图
function initMap(initParam) {
  let { L, id = 'map', mapType, zoomControl, maxZoom = 20, minZoom = 3 } = initParam;
  let map = L.map(id, {
    ...initParam,
    attributionControl: false,
    zoomControl: false,
    crs: L.CRS.EPSG3857
  });
  L.tileLayer(mapType, { maxZoom, minZoom }).addTo(map);
  return map;
}

// 判断地块多边形顶点是否存在交叉
function pointsIsCrossing(latlngs) {
  let flag = false;
  if (latlngs.length > 3) {
    latlngs.forEach((item) => {
      item.x = item.lat;
      item.y = item.lng;
    });
    for (var i = 0; i < latlngs.length - 1; i++) {
      for (var j = i + 2; j < latlngs.length - 1; j++) {
        // 相邻的两条边相交
        if (L.LineUtil.segmentsIntersect(latlngs[i], latlngs[i + 1], latlngs[j], latlngs[j + 1])) {
          return true;
        } else {
          flag = false;
        }
      }
    }
  }
  return flag;
}

function formatArea(area) {
  let formatValue = 0;
  area = parseFloat(area || 0);
  if (!area) {
    formatValue = 0;
  } else if (area >= 1000) {
    formatValue = Math.round(area);
  } else if (area < 1000) {
    formatValue = area.toFixed(2);
  }
  return formatValue;
}

function scaleBounds(L, currentBounds, level = 0.5) {
  var center = currentBounds.getCenter();
  var northEast = currentBounds.getNorthEast();
  var southWest = currentBounds.getSouthWest();
  var newNorthEast = L.latLng(center.lat - (center.lat - northEast.lat) / level, center.lng - (center.lng - northEast.lng) / level);
  var newSouthWest = L.latLng(center.lat + (southWest.lat - center.lat) / level, center.lng + (southWest.lng - center.lng) / level);
  var newBounds = L.latLngBounds(newNorthEast, newSouthWest);
  return newBounds;
}

export { unitConverter, initMap, pointsIsCrossing, formatArea, scaleBounds };
