import Vue from 'vue';
import Vuex from 'vuex';
import { getDicAll, dictListApi, farmingTypes } from '@/api/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    delGuideNum: 0,
    dicAll: {},
    dicList: [],
    farmingType: [],
    breadList: []
  },

  mutations: {
    set_delGuideNum(state, delGuideNum) {
      state.delGuideNum = delGuideNum;
    },
    set_dicAll(state, val) {
      state.dicAll = val;
    },
    set_dictList(state, val) {
      state.dictList = val;
    },
    set_farmingType(state, val) {
      state.farmingType = val;
    },
    set_breadList(state, val) {
      state.breadList = val;
    }
  },

  actions: {
    async getDicAll({ state, commit }) {
      if (_.isEmpty(state.dicAll)) {
        const res = await getDicAll();
        commit('set_dicAll', res);
        return res;
      } else {
        return state.dicAll;
      }
    },
    async getDictList({ state, commit }) {
      if (_.isEmpty(state.dictList)) {
        const res = await dictListApi();
        commit('set_dictList', res);
        return res;
      } else {
        return state.dictList;
      }
    },
    async getFarmingTypes({ state, commit }) {
      if (_.isEmpty(state.farmingType)) {
        const res = await farmingTypes();
        commit('set_farmingType', res);

        return res;
      } else {
        return state.farmingType;
      }
    },
    async getContractStateList({ state, commit }, key) {
      return [
        {
          id: 1,
          name: '进行中'
        },
        {
          id: 2,
          name: '已完成'
        },
        {
          id: 3,
          name: '已废弃'
        }
      ];
    },
    async getDicOptionsList({ state, commit }, key) {
      if (key == 'land.level') {
        return [
          {
            id: 0,
            name: '无级别'
          },
          {
            id: 1,
            name: '一级地'
          },
          {
            id: 2,
            name: '二级地'
          },
          {
            id: 3,
            name: '三级地'
          }
        ];
      }
      if (_.isEmpty(state.dicAll)) {
        const res = await getDicAll();
        commit('set_dicAll', res);
        let list = res[key];
        let options = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const e = list[key];
            options.push({ id: Number(key), name: e });
          }
        }
        return options;
      } else {
        let res = state.dicAll;
        let list = res[key];
        let options = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const e = list[key];
            options.push({ id: Number(key), name: e });
          }
        }
        return options;
      }
    }
  },

  getters: {
    delGuideNum(state) {
      return state.delGuideNum;
    },
    dicKey(state) {
      return (key) => {
        let list = state['key'];
        let options = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const e = list[key];
            options.push({ id: Number(key), name: e });
          }
        }
        return options;
      };
    }
  }
});
